import "./src/styles/index.css";
import React from "react";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LfJXC4qAAAAAO4xBVMOzoaUHufaVxYb90lRU0ET">
      {element}
    </GoogleReCaptchaProvider>
  );
};
